import React, { useState } from 'react';
import Ticker from 'react-ticker';

import ConceptLink from './ConceptLink';

import * as Sentry from '@sentry/react';

import MailchimpSubscribe from 'react-mailchimp-subscribe';
const mailchimpUrl = '//foodriders.us1.list-manage.com/subscribe/post?u=30a311af7ac2bebe4d9ffac49&amp;id=768490ade8';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';

import instagramIcon from 'images/landing/icons/instagram.svg';
import eastsideradioIcon from 'images/landing/icons/eastsideradio.svg';
import frBlogIcon from 'images/landing/icons/blog.svg';

import styles from './Landing.module.scss';

const CustomForm = ({ status, message, onValidated }) => {
  let email;

  const subscribeEmailToList = () => {
    email && email.value.indexOf('@') > -1 && onValidated({ EMAIL: email.value });
  }

  return (
    <div className={styles.subscribeForm}>
      {status === 'sending' ? (
        <div>Subscribing...</div>
      ) : status === 'error' ? (
          <div dangerouslySetInnerHTML={{ __html: message }} />
      ) : status === 'success' ? (
        <div>Thanks! We'll be in touch!</div>
      ) : (
        <>
          <label className={styles.subscribeFormLabel} htmlFor='email_field'>
            Subscribe
          </label>

          <input
            ref={node => (email = node)}
            type='email'
            className={styles.subscribeFormEmailField}
            placeholder='Your Email'
          />

          <button className={styles.subscribeFormButton} onClick={subscribeEmailToList}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </>
      )}
    </div>
  );
};

const Landing = (props) => {
  const [menus, setMenus] = useState(props.menus);
  const [showingAbout, setShowingAbout] = useState(false);

  const toggleShowingAbout = () => {
    setShowingAbout(isShowing => !isShowing);
  }

  return (
      <div className={styles.landingContainer}>
        <div>
          <header className={styles.header}>
            <h1 className={styles.headerTitle}>FoodRiders</h1>

            <MailchimpSubscribe
              url={mailchimpUrl}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />

            <section className={styles.tabsContainer}>
              <button
                className={styles.tab}
                onClick={toggleShowingAbout}
              >
                About
              </button>
              {showingAbout && <div>
              <p className={styles.content}>A collective of Culinary, Design, Tech, and Experience professionals who forged and alliance to rethink the restaurant model. <br />Foodriders looks to blur the online and offline world of food, while focusing on verifiable transparency. Old World Hospitality Meets Nerdy Shit!<br />We love making things from scratch, Web3, hot sauce, and making people happy. If you wanna know more just ask!</p>
                </div>
              }
            </section>
          </header>

          <section id='concepts'>
            {menus.map(menu => (
              <ConceptLink key={menu.id} concept={menu} />
            ))}
          </section>

          <section className={styles.tickerContainer}>
            <Ticker direction='toRight'>
              {({ index }) => <span>GET FUN >>> GET A RIDE >>></span>}
            </Ticker>
          </section>
        </div>

        <section className={styles.footerLinks}>
          <a
            className={styles.outlink}
            target='_blank'
            href='https://www.instagram.com/durodematar.pt/'
          >
            <img src={instagramIcon} alt='Duro de Matar on Instagram' />
          </a>

          <a
            className={styles.outlink}
            target='_blank'
            href='https://onlyfansfoodriders.wordpress.com/blog/'
          style={{ width: '6rem' }}
          >
            <img src={frBlogIcon} alt="Foodriders Blog" />
          </a>

          <a
            className={styles.outlink}
            target='_blank'
            href='https://esr.li/'
          >
            <img src={eastsideradioIcon} alt="East Side Radio's Website" />
          </a>
        </section>
      </div>);
};

export default Landing;
