import React from "react";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "@sentry/react";

import Landing from "./Landing";
import NotFound from "./Errors/NotFound";

const LandingRoot = (props) => {
  return (
    <ErrorBoundary beforeCapture={scope => { scope.setTag("location", "landing"); }} fallback={<NotFound />} showDialog>
      <>
        <Helmet>
          <title>FoodRiders - Get a ride!</title>
        </Helmet>
      
        <Landing {...props} />
      </>
    </ErrorBoundary>
  )
}

export default LandingRoot;