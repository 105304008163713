import React from "react";
import { createRoot } from 'react-dom/client';

import LandingRoot from "../components/LandingRoot";

document.addEventListener("DOMContentLoaded", () => {
  const mountPoint = document.getElementById("landing-mount")
  const { dataset } = mountPoint;
  const props = JSON.parse(dataset.props);

  let rootDiv = document.createElement("div");
  rootDiv.setAttribute("id", "root");
  document.body.appendChild(rootDiv);
  let root = createRoot(rootDiv);

  root.render(<LandingRoot {...props} />);
});